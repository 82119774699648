.h1 {
    @apply text-[64px] font-bold leading-[96px];
}

.h2 {
    @apply text-[48px] font-bold leading-[72px];
}

.h3 {
    @apply text-[32px] font-semibold leading-[48px];
}

.h4 {
    @apply text-[24px] font-bold leading-[36px];
}

.h5 {
    @apply text-[20px] font-bold leading-[30px];
}

.body1 {
    @apply text-[20px] leading-[30px];
}

.body2 {
    @apply text-[18px] leading-[27px];
}

.body3 {
    @apply text-[16px] leading-[24px];
}
.body4 {
    @apply text-[12px] leading-[18px];
}

.body5 {
    @apply text-[10px] leading-[15px];
}

@media (min-width: 640px) {
    .h1-responsive {
        @apply h1;
    }
    .h2-responsive {
        @apply h2;
    }

    .h4-responsive {
        @apply h4;
    }

    .body1-responsive {
        @apply body1;
    }

    .body2-responsive {
        @apply body2;
    }

    .body3-responsive {
        @apply body3;
    }
}

@media (max-width: 640px) {
    .h1-responsive {
        @apply h4;
    }

    .h2-responsive {
        @apply h4;
    }

    .h4-responsive {
        @apply body3;
    }

    .body1-responsive {
        @apply body3;
    }

    .body2-responsive {
        @apply body3;
    }

    .body3-responsive {
        @apply body4;
    }
}
