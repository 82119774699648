.markdown-body h1 {
    font-size: 2em;
    margin: 0.67em 0;
    font-weight: bold;
    border-bottom: 1px solid #e1e4e8;
    padding-bottom: 0.3em;
}

.markdown-body h2 {
    font-size: 1.5em;
    margin: 0.75em 0;
    font-weight: bold;
    border-bottom: 1px solid #e1e4e8;
    padding-bottom: 0.3em;
}

.markdown-body h3 {
    font-size: 1.25em;
    margin: 0.85em 0;
    font-weight: bold;
}

.markdown-body h4 {
    font-size: 1em;
    margin: 0.95em 0;
    font-weight: bold;
}

.markdown-body h5 {
    font-size: 0.875em;
    margin: 1em 0;
    font-weight: bold;
}

.markdown-body h6 {
    font-size: 0.85em;
    margin: 1.25em 0;
    font-weight: bold;
}

/* Additional styles for paragraphs, links, etc., to match GitHub style */
.markdown-body p {
    margin-bottom: 16px;
    line-height: 1.5;
}

.markdown-body a {
    color: #0366d6;
    text-decoration: none;
}

.markdown-body a:hover {
    text-decoration: underline;
}

.markdown-body ol {
    list-style-type: decimal;
    margin-left: 2em;
}

.markdown-body ul {
    list-style-type: disc;
    margin-left: 2em;
}

.markdown-body li {
    margin-bottom: 0.5em;
}
